.contact_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #45aaf2e6;
    padding: 2rem;
    border-radius: 15px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
    overflow: hidden;
    color: white;



    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    .contact_left_container {
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .contact_text {
            font-size: 0.85rem;
            margin-bottom: 1rem;

        }
        .contact_left {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            .icon {
                width: 50px;
                height: 50px;
                background: #ffffff;
                color: #45aaf2;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                margin-right: 1rem;
        }
           p {
            font-size: 0.85rem;
           }
        }
        .social_icons {
            display: flex;
            margin-top: 1rem;

            div {
                margin-right: 1rem;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    font-size: 1.3rem;
                    color: #fff;
                }
            }
        }

        
    }
    .contact_right {
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        .row {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
            gap: 0.5rem;

            input {
                border: none;
                outline: none;
                margin-bottom: 0.5rem;
                padding: 1rem 1.5rem;
                background: #f3f3f3;
                border-radius: 5px;
                font-size: 0.85rem;
                font-family: "Poppins";
                color: #45aaf2
            }
            textarea {
                border: none;
                outline: none;
                margin-bottom: 0.5rem;
                padding: 1rem 1.5rem;
                background: #f3f3f3;
                border-radius: 5px;
                resize: none;
                min-height: 150px;
                font-size: 0.85rem;
                font-family: "Poppins";
                color: #45aaf2
            }
        }

        .btn {
            background: #ffffff;
            border: none;
            border-radius: 8px;
            color: #5bb2f4;
            font-size: .85rem;
            height: 40px;
            margin-top: 1rem;
            padding: 5px 10px;
            text-align: center;
            width: 100px;
        }
        .btn:hover{
            transform:scale(1.1);
            animation:cubic-bezier(1, 0, 0, 1) 3s;
        }
        span#sentMessage{
            color: #fff;
        }
    }
    a#goUp {
        color: #fff;
        display: inline-flex;
        height: 52px;
        width: 52px;
        background-color: #45aaf2;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        line-height: 1px;
        font-size: 45px;
        -webkit-text-stroke: 1px white;
        position: relative;
        right: 0px !important;
        bottom: -30px;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
    }
    *[data-tooltip] {
        position: relative;
      }
      
      *[data-tooltip]::after {
        content: attr(data-tooltip);
      
        position: absolute;
        top: 15px;
        right: -160px;
        width: 150px;
      
        pointer-events: none;
        opacity: 0;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -ms-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out;
        color: #fff;
        display: block;
        font-size: 12px;
        line-height: 16px;
        background: #44AAF2;
        padding: 2px 0px;
        border: none;
        box-shadow: none;
        border-radius: 20px 20px;
        text-align: center;
      }
      
      *[data-tooltip]:hover::after {
        opacity: 1;
      }
}