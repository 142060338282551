* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
body {
    background: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
img {
    width: 100%;
}
.container {
    max-width:1224px;
    min-height: 100vh;
    padding: 7rem 1.5rem 0 1.5rem;
    margin: 0 auto;
    background: #ffffffb8;
}
@font-face {
    font-family: "Poppins";
    src: url(./Font/Poppins-Medium.ttf);
}
.title {
    text-align: center;
    margin-bottom: 3rem;
}
.title span {
    color: #45aaf2;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 800;
    letter-spacing: 5px;
}
.title h1 {
    color: #45aaf2;
    font-size: 3rem;
    position: relative;
}
@media screen and (max-width: 500px) {
    .container {
        padding: 7rem 1rem 0 1rem;
    }
    .title h1 {
        font-size: 2.5rem;
    }
}
  html {
    --scrollbarBG: transparent;
    --thumbBG: #44AAF2;
  }
  body::-webkit-scrollbar {
    height:0px;
    width: 12px;
  }

  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 0px;
  }