#HomeBG {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9rem 1rem 0 1rem;
    z-index:1;
    background-color: #ffffffb8;

    .background{
        background-color: #ffffffb8;
    }

    div#tsparticles.background{
        background-color: #ffffffb8;
    }
    div{
        background-color: #ffffffb8;
        background: #ffffffb8;
    }
}
