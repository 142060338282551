@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&family=Roboto+Mono:wght@100&display=swap');

div#descRow.row {
  padding: 15px 30px 50px 30px !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #4a6584;
}

.select {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 5rem;
    
    
    button {
        color: #4a6584;
            background: #fff;
            padding: 0.65rem 1rem;
            border-radius: 10px;
            font-size: 0.85rem;
            text-transform: capitalize;
            cursor: pointer;
            border: none;
            margin-top: 1rem;
            font-family: "Poppins";
            border: solid 1px #45aaf2;

            &.active {
                background: #45aaf2;
                color: #fff;
            }
    }
}
.skills {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  row-gap: 4rem;

  @media screen and(max-width: 500px) {
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
  }
  .tools {
    display: flex;
    justify-content: center;
    svg {
      width: 100px;
      height: 100px;
      color: #45aaf2;
    }
  }
}

*[data-tooltip] {
  position: relative;
}

*[data-tooltip]::after {
  content: attr(data-tooltip);

  position: absolute;
  top: -24px;
  right: 6px;
  width: 150px;

  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .15s ease-in-out;
  -moz-transition: opacity .15s ease-in-out;
  -ms-transition: opacity .15s ease-in-out;
  -o-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
  color: #fff;
  display: block;
  font-size: 12px;
  line-height: 16px;
  background: #44AAF2;
  padding: 2px 0px;
  border: none;
  box-shadow: none;
  border-radius: 20px 20px;
  text-align: center;
}

*[data-tooltip]:hover::after {
  opacity: 1;
}

.tools:hover{
  animation: wag .8s infinite;
}


.experiences {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #45aaf2;
  font-size: .8rem;
  padding: 0px 20px;
  flex-wrap: nowrap;
  display: contents !important;
  margin-bottom: 10px;
  justify-content: space-evenly;

    .experience {
      display: flex;
    justify-content: flex-start;
    align-items: center;

      .desc {
        width: 300px;
        padding-left: 2.5rem;
        color: #45aaf2;
        
        p {
          font-size: 0.75rem;
          opacity: 0.6;
          padding: 20px 0px;

          @media screen and(max-width: 405px) {
            font-size: 0.65rem;
          }
        }
      }
      
      span {
          font-size: 1.75rem;
          padding-left: 2.5rem;

          @media screen and(max-width: 405px) {
            font-size: 1rem;
          }
        }        
        .position {
          width: 300px;
          padding-left: 2.5rem;
           h3 {
             font-size: 1.1rem;
             @media screen  and(max-width: 405px){
               font-size: 0.85rem;
             }
           }
          p {
            font-size: 0.75rem;
            opacity: 0.6;

            @media screen and(max-width: 405px) {
              font-size: 0.65rem;
            }
          }
        }
        
    }
  
}

.finishes_container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  text-align: center;
  flex-wrap: wrap;
  gap: 2rem;
  color: #45aaf2;

  .number {
    color: #45aaf2;
    font-size: 1.7rem;
  }
}

@keyframes wag {
  50% {transform: rotate(45deg)}
}