#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9rem 1rem 0 1rem;



   .home_image {
    background: #45aaf2;
    max-width: 200px;
    height: 200px;
    justify-self: center;
    border-radius: 50%;
    box-shadow: 0 0 20px #45aaf2;

    img {
        object-fit: cover;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        z-index:10;
    }
    
}

   .portfolioWord {
    color: #506c8d;
    transform: rotate(-90deg);
    position: absolute;
    margin: 0;
    font-size: 80px;
    z-index: -500;
    right: -161px;
    top: 790px;
 }

   
    .profile_text {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        h3 {
            color: #45aaf2;
            font-size: 2rem;
            @media screen and (max-width: 500px) {
                font-size: 1.5rem;
            }
            span {
                color: #45aaf2;
            }
        }
        .job {
            color: #496579;
            opacity: 0.7;
            font-size: 0.85rem;
        }
        .text {
    background-color: #45aaf2;
    border-radius: 6px;
    color: #fff;
    font-size: 3rem;
    line-height: 1.3;
    margin-top: 2rem;
    padding: 15px 70px;
    text-align: center;
    text-transform: uppercase;
        

            @media screen and (max-width: 800px) {
                font-size: 3.5rem;
                 margin-top: 2rem;
            }
            @media screen and(max-width: 530px) {
                font-size: 2.5rem;
            }
        }
        a {
            margin-top: 3rem;
            text-decoration: none;
            color: #fff;
            background: #45aaf2;
            padding: 0.65rem 1rem;
            border-radius: 10px;
            font-size: 0.85rem;
            text-transform: capitalize;
                }
        div{
            position: absolute;
            background: rgba(255,255,255,0.15);
            color: black;
            padding: 0.35rem 1rem;
            border-radius: 25px;
            font-size: 0.85rem;
            cursor: pointer;
        }
        @media screen  and (max-width: 800px) {
            div {
                display: none;
            }
        }
        .web {
            right: -1rem;
            top: 3.5rem;
        }
        .ui {
            top: 2rem;
            left: 2rem;
        }
        .freelance {
            bottom: 2rem;
            left: 0;
        }
    }
    #tsparticles {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      

}