.about_container {
    display: grid;
    place-items: center;
    position: relative;
    color: #496579;


    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    .about_left {
        background: #45aaf2;
        max-width: 350px;
        height: 350px;
        justify-self: center;
        border-radius: 50px 50px;
        box-shadow: 0 0 20px #45aaf2;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50px 50px;
        }
        
    }
    *[data-tooltip] {
        position: relative;
      }
      
      *[data-tooltip]::after {
        content: attr(data-tooltip);
      
        position: absolute;
        top: -24px;
        right: 6px;
        width: 150px;
      
        pointer-events: none;
        opacity: 0;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -ms-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out;
        color: #fff;
        display: block;
        font-size: 12px;
        line-height: 16px;
        background: #44AAF2;
        padding: 2px 0px;
        border: none;
        box-shadow: none;
        border-radius: 20px 20px;
        text-align: center;
      }
      
      *[data-tooltip]:hover::after {
        opacity: 1;
      }

      .scrollable-text-box {
        height: 100%;
        padding: 30px 30px;
        margin: 0px 0px 15px 0px;
        border-right: none;
        border-left: none;
        background: #46a8efd6;
        color: white;
        border-radius: 10px;
        
    }
    a.contact-link{
        color:white;
        text-decoration: none;
    }
   
    .about_right {
        color: #fff;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        transform: translateY(20px) !important;

        @media screen and (max-width: 700px) {
            padding: 0;
            margin-top: 2rem;
        }
        
        p {
            font-size: 0.85rem;
            margin-bottom: 1rem;max-width: 450px;
        }

    
        .bio {
            display: flexbox;
            font-size: 0.85rem;
            max-width: 200px;
            margin-bottom: 1rem;
            white-space: nowrap;

            .bioKey {
                display: flexbox;
                align-items: center;
                gap: 0.5rem;
                margin-right: 3rem;
                white-space: nowrap;
                color: #45aaf2;
            }
        }

        a {
            text-decoration: none;
            color: #fff;
            background: #45aaf2;
            padding: 0.65rem 1rem;
            border-radius: 10px;
            font-size: 0.85rem;
            text-transform: capitalize;
            width: 165px;
        }

    }
    span.bioKey{
        color: #45aaf2;
    }
    a.download{
        text-decoration: none;
        color: #ffffff;
        background: #45aaf2;
        padding: 0.65rem 1rem;
        border-radius: 10px;
        font-size: 0.85rem;
        margin: 6px;
        margin-top: 3rem !important;
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
    }
    svg{
        margin-left:3px;
    }
    
}