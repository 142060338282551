
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background: #fff;
   
    &.active {
        background: rgba(255,255,255,0.15);
        backdrop-filter: blur(10px);
    }
.closeMenu {
        -webkit-backdrop-filter: blur(.5px) !important;
        backdrop-filter: blur(0.5px) !important;
        background-image: linear-gradient(45deg,rgba(6, 36, 47, .79),rgba(0,0,0,.78));
        border-radius: 50%;
        height: 4rem;
        position: fixed;
        right: 0;
        top: 0;
        width: 4rem;
        color: white !important;
       }
}
.Nav_container {
    position: relative;
    max-width: 1224px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 2.5rem;
    margin-left: auto;
    margin-right: auto;
    height:70px;
    .logo {
        align-items: center;
        background: #45aaf2;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: space-evenly;
        padding: 10PX;
        white-space: nowrap;
        width: 110px;
    }
   .social_icons {
    display: flex;
       @media screen and (max-width: 800px) {
        display: none;
       }
       div {
           margin-left: 1rem;

        svg {
        cursor: pointer;
        color: #fff;
        opacity: 0.6;
        transition: 0.3s;
        z-index: 5;
        
        &:hover {
            opacity: 1;
        }
       }
       
     }
   }
   canvas {
    width: 100vw;
    height: 100vh;
}
   
   .menu {
    background: transparent;
    width: 50px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: none;
    cursor: pointer;
    color: #45aaf2;

    @media screen and (max-width: 800px) {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
    width: 100%;
    height: 100%;
    }
   }
   
   .menuX {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: -50%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    padding: 0px 23%;

    
    svg {
        align-items: center;
        color: #45aaf2;
        flex-direction: column;
        height: 5vh;
        justify-content: center;
        position: fixed;
        top: 10%;
        width: 100%;
        left: 36%;
    }
    li {
        list-style: none;
        margin-top: 2rem;

        a {
    color: #45aaf2;
    font-size: 2.5rem;
    opacity: .8;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .3s ease;
    letter-spacing: .35rem;
    font-weight: bolder;
            &:hover {
                opacity: 1;
            }
        }
    }
   }
}
.nav_links {
    display: flex;
    list-style: none;


    li {
        margin-left: 3rem;

        @media screen and (max-width: 800px){
            display: none;
        }

        a {
            text-decoration: none;
            transition: 0.3s;
            color: #45aaf2;
            text-transform: uppercase;
            font-size: 0.85rem;
            opacity: 0.6;
            &:hover {
               color: #44AAF2;
               opacity: 1;
            }
        }
    }    

}